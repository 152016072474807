import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'



const IndexPage = () => {


    const red = {
        color: 'red'
    }

  return(
    <Layout>
      <SEO title="Home" />
      <Grid container spacing={3} justify="center">
        <Grid item xs={8}>
          <h1><span style={red}>AI</span> Lab</h1>
          <h3> Research</h3>
            <p>(Comming soon)</p>
            <p>AI in Music Unit</p>
            <p>AI in Health Unit</p>
            <h3>Teaching</h3>
            <p><a target="_blank" href="https://master-artificialintelligence.com">Master in Artificial Intelligence and Deep Learning</a> <br/>(online, in English)</p>
            <p><a target="_blank" href="https://master-deeplearning.com">Master en Deep Learning </a><br/>(semi-presencial, en Español)</p>

        </Grid>
      </Grid>
      <Divider />

    </Layout>
  )
}

export default IndexPage
